<template>
  <v-container>
    <v-row justify="center" v-if="error">
      <v-col cols="12" sm="6" class="offset-sm3">
        <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="8" class="offset-sm3">
        <h4 class="text-center">{{ $t('Common.signup_title') }}</h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="8" class="offset-sm3">
        <h4
          class="text-center"
          v-html="$t('Common.signup_title_as_chef', { link: chefPortalLink })"
        ></h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="8" class="offset-sm3">
        <v-card>
          <v-card-text>
            <v-container>
              <form @submit.prevent="onSignup">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="name"
                      label="Your name"
                      id="name"
                      data-test="name"
                      v-model="name"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" class="pr-4">
                    <v-text-field
                      name="email"
                      label="Email"
                      id="email"
                      v-model="email"
                      type="email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="phone"
                      label="Phone"
                      id="phone"
                      v-model="phone"
                      mask="phone"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" class="pr-4">
                    <v-text-field
                      name="password"
                      label="Password"
                      hint="At least 6 characters"
                      min="6"
                      id="password"
                      v-model="password"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="confirmPassword"
                      label="Confirm Password"
                      id="confirmPassword"
                      v-model="confirmPassword"
                      validate-on-blur
                      type="password"
                      :rules="[matchPassword]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="my-3">
                  <v-col cols="12" v-html="$t('Common.signup_agree_terms')">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="text-center">
                      <v-btn
                        rounded
                        large
                        type="submit"
                        :disabled="!comparePasswords"
                        :loading="loading"
                        class="takein_logo white--text"
                      >
                        Sign up

                        <span slot="loader" class="custom-loader">
                          <v-icon light>cached</v-icon>
                        </span>
                      </v-btn>
                      or <router-link router to="/signin">Sign In</router-link>
                      {{ $t('Common.signin_has_account') }}
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-5">
                  <v-col cols="12"> {{ $t('Common.signup_options') }} </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-btn
                      id="google-sign"
                      rounded
                      :disabled="loading"
                      :loading="loading"
                      @click.prevent="onSigninGoogle"
                      color="info white--text"
                      class="white--text"
                    >
                      Google
                      <v-icon right dark>fab fa-google white-text</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn
                      id="face-book-signup"
                      rounded
                      :disabled="loading"
                      :loading="loading"
                      @click.prevent="onSigninFacebook"
                      class="blue white--text"
                    >
                      Facebook
                      <v-icon right dark>fab fa-facebook-f</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-btn
                      id="twitter-signup"
                      rounded
                      :disabled="loading"
                      :loading="loading"
                      @click.prevent="onSigninTwitter"
                      info
                      class="info white--text"
                    >
                      Twitter
                      <v-icon right dark>fab fa-twitter white-text</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      name: null,
      phone: null,
      email: '',
      password: '',
      confirmPassword: '',
      matchPassword: v =>
        (this.password && v === this.password) || "The passwords don't match"
    }
  },
  metaInfo: () => ({
    title:
      'Sign up now to TakeIn local: new and healthy local food experiences are waiting for you.',
    titleTemplate: '%s',

    meta: [
      {
        name: 'description',
        content:
          'TakeIn is unearthing a new world of fresh local food options and placing them at your fingertip. Our mission is to democratize food production and help millions of people build local food businesses that support families and enrich communities'
      },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }),
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        let redirect = this.$route.query.redirect
        if (redirect) {
          this.$router.push(redirect)
        } else {
          this.$router.push('/')
        }
      }
    }
  },
  mounted() {
    console.log('Signup type:', this.$route.params.type)
  },
  computed: {
    ...mapGetters({
      //chefPortalAddress: 'chefPortalAddress',
      loading: 'loading'
    }),
    comparePasswords() {
      return this.password === this.confirmPassword
    },
    user() {
      return this.$store.getters.user
    },
    error() {
      return this.$store.getters.error
    },
    loading() {
      return this.$store.getters.loading
    }
  },

  methods: {
    onSignup() {
      this.$store.dispatch('signUserUp', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        password: this.password,
        type: 'customer',
        invitation_id: this.$route.query.invitation_id || null
      })
    },
    onSigninGoogle() {
      this.$store.dispatch('signUserInGoogle', {
        type: 'customer',
        invitation_id: this.$route.query.invitation_id || null
      })
    },
    onSigninFacebook() {
      this.$store.dispatch('signUserInFacebook', {
        type: 'customer',
        invitation_id: this.$route.query.invitation_id || null
      })
    },
    onSigninTwitter() {
      this.$store.dispatch('signUserInTwitter', {
        type: 'customer',
        invitation_id: this.$route.query.invitation_id || null
      })
    },
    onDismissed() {
      this.$store.dispatch('clearError')
    }
  }
}
</script>
